import React from 'react'
import { Link } from 'react-router-dom'
import { Container } from '../../core/components'

import './not_found.scss'

function NotFound() {
  return (
    <Container>
      <div className="notFound">
        <h1>Oops! Page not found.</h1>

        <Link to="/">Return to Home</Link>

        <div className="xxl-separator" />
      </div>
    </Container>
  )
}

export default NotFound
