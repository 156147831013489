import React from 'react'
import ProgressiveImage from 'react-progressive-graceful-image'
import { Link } from 'react-router-dom'

function ArticleCard({ data }) {
  return (
    <div className="blog__content__articleCard">
      <Link to={`/blog/${data.id}`}>
        <ProgressiveImage src={data.thumbnail}>
          {(src, loading) => (
            <img
              src={data.thumbnail}
              alt={`${data.title} — Sage Tech — Mobile Development Agency`}
              className={`image${loading ? ' loading' : ' loaded'}`}
            />
          )}
        </ProgressiveImage>
      </Link>

      <div className="xs-separator" />

      <Link to={`/blog/${data.id}`}>
        <h3>{data.title}</h3>
      </Link>

      <div className="xs-separator" />

      <p>{data.preview}</p>

      <div className="xs-separator" />

      <Link
        to={`/blog/${data.id}`}
        className="blog__content__articleCard__readMore">
        Read more
      </Link>
    </div>
  )
}

export default ArticleCard
