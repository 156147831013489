import React from 'react'
import { Container } from '../../core/components'
import { caseStudies } from '../../core/case_studies'

import './home.scss'
import { BookButton, BookButtonContainer } from './components'
import { Link } from 'react-router-dom'

function Home() {
  return (
    <Container>
      <section className="hero">
        <h1 className="animate__animated animate__fadeInDown">
          Welcome to Sage Tech
        </h1>
        <p className="animate__animated animate__fadeInUp">
          Expert mobile and web development solutions tailored to your business
          needs.
        </p>
        <BookButton />
      </section>

      <section className="services">
        <h2>Our Services</h2>
        <div className="services-grid">
          <div className="service-item">
            <h3>Mobile Development</h3>
            <p>
              Our experienced team of mobile developers will create cutting-edge
              apps for your business.
            </p>
          </div>
          <div className="service-item">
            <h3>Frontend Development</h3>
            <p>
              Our talented team of frontend developers will craft visually
              appealing, user-friendly interfaces for seamless user experiences
              across your digital platforms.
            </p>
          </div>
          <div className="service-item">
            <h3>Backend Development</h3>
            <p>
              Our highly skilled team of backend developers will design and
              implement robust, scalable server-side solutions for your business
              needs.
            </p>
          </div>
          {/* <div className="service-item">
            <i className="icon fas fa-cloud"></i>
            <h3>Cloud Solutions</h3>
            <p>
              We offer a variety of cloud services to help your business scale
              and grow efficiently.
            </p>
  </div> */}
        </div>
      </section>

      <section id="portfolio">
        <h2>Portfolio</h2>
        <div className="portfolio-grid">
          {caseStudies.map((item, index) => {
            return (
              <Link
                to={'/case-study/' + item.id}
                className="portfolio-item"
                key={index}>
                <img src={item.img} alt={`Logo | ${item.title}`} />
                <div className="title-overlay">
                  <h3>{item.title}</h3>
                </div>
              </Link>
            )
          })}
        </div>
      </section>

      <BookButtonContainer />

      {/* <section id="testimonials">
        <h2>Testimonials</h2>
        <div className="testimonials-grid"></div>
      </section> */}
    </Container>
  )
}

export default Home
