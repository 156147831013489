import React from 'react'
import BookButton from './book_button'
import './book_button.scss'

function BookButtonContainer() {
  return (
    <section className="bookButton__container">
      <h2>Contact Us</h2>
      <p>
        Get in touch with our team to discuss your project and how we can help.
      </p>
      <BookButton />
    </section>
  )
}

export default BookButtonContainer
