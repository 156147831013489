import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Logo from '../assets/logo.png'

import './header.scss'

function Header() {
  const navigate = useNavigate()
  const { pathname, state } = useLocation()

  const scrollToView = (id, behavior = 'smooth') => {
    const container = document.getElementById(id)
    container.scrollIntoView({ behavior }, true)
  }

  useEffect(() => {
    if (state) return scrollToView('portfolio')
    scrollToView('container', 'instant')
  }, [state])

  const handleScrollLink = ev => {
    ev.preventDefault()

    if (pathname !== '/') {
      return navigate('/', { state: true })
    }
    scrollToView('portfolio')
  }

  const handleLink = ev => {
    ev.preventDefault()
    const nextPathname = ev.currentTarget.pathname

    if (nextPathname === pathname) {
      return scrollToView('container', 'smooth')
    }

    navigate(nextPathname)
    scrollToView('container', 'instant')
  }

  return (
    <header>
      <nav>
        <Link to="/" onClick={handleLink}>
          <img src={Logo} alt="Sage Tech Logo" className="logo" />
        </Link>
        <ul>
          <li>
            <Link to="/" onClick={handleLink}>
              Home
            </Link>
          </li>
          <li>
            <a href="#portfolio" onClick={handleScrollLink}>
              Portfolio
            </a>
          </li>
          <li>
            <Link to="/blog" onClick={handleLink}>
              Blog
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={handleLink}>
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Header
