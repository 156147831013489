import React from 'react'
import { articles } from '../../core/articles'
import { Container } from '../../core/components'

import './blog.scss'
import { ArticleCard } from './components'

function Blog() {
  return (
    <Container
      title="Blog - Mobile Development Agency"
      content="Blog dedicated for Mobile Development Topics">
      <div className="blog">
        <div className="blog__top">
          <h1>Blog</h1>
        </div>

        <div className="blog__content">
          {articles.map(article => {
            return <ArticleCard data={article} key={article.id} />
          })}

          <div className="l-separator" />
        </div>
      </div>
    </Container>
  )
}

export default Blog
