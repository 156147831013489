import React from 'react'
import { RouterProvider } from 'react-router-dom'
import router from './core/navigation'

function App() {
  return <RouterProvider router={router} />
}

console.error = () => {
  return
}

export default App
