import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Container } from '../../core/components'

import { caseStudies } from '../../core/case_studies'

import './case_study.scss'
import { BookButtonContainer } from '../home/components'

function CaseStudy() {
  const [data, setData] = useState()

  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    setData(getCaseStudy(id, navigate))
  }, [id, navigate])

  if (!data) return <></>

  return (
    <Container
      title={`${data.title} | Mobile Development Agency`}
      content={`${data.title}. Blog dedicated for Mobile Development Topics`}>
      <section className="case-study">
        <main>
          <h1>{data.title}</h1>
          <img src={data.img} alt={data.title} className="case-study-image" />

          <div className="section">
            <h2>Overview</h2>
            <p>{data.content.overview}</p>
          </div>

          <div className="section">
            <h2>Objective</h2>
            <p>{data.content.objective}</p>
          </div>

          <div className="section">
            <h2>Approach</h2>
            <p>{data.content.approach}</p>
            <ul>
              {data.content.steps.map((step, index) => {
                return <li key={index}>{step}</li>
              })}
            </ul>
          </div>

          <div className="section">
            <h2>Result</h2>
            <p>{data.content.result}</p>
          </div>
        </main>
      </section>

      <BookButtonContainer />

      {/* <section className="call-to-action">
        <div className="container">
          <h2>Interested in working with us?</h2>
          <p>Book a call with our team to discuss your project.</p>
          <a href="#" className="cta-btn">
            Book a Call
          </a>
        </div>
      </section> */}
    </Container>
  )
}

function getCaseStudy(id, navigate) {
  const caseStudy = caseStudies.find(caseStudy => caseStudy.id === id)

  if (caseStudy) return caseStudy
  navigate('/not-found', { replace: true })
}

export default CaseStudy
