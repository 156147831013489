import React, { useEffect, useState } from 'react'
import { Calendar } from 'feather-icons-react/build/IconComponents'
import { useNavigate, useParams } from 'react-router'
import { Container } from '../../core/components'
import { BookButtonContainer } from '../home/components'

import { articles } from '../../core/articles'

import './article.scss'

function Article() {
  const [data, setData] = useState()

  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    setData(getArticle(id, navigate))
  }, [id, navigate])

  if (!data) return <></>

  return (
    <Container
      title={`${data.title} | Mobile Development Agency`}
      content={`${data.title}. Blog dedicated for Mobile Development Topics`}>
      <div className="article">
        <div className="article__top">
          <h1>{data.title}</h1>
        </div>

        <div className="article__main">
          <div className="article__content">
            <img
              src={data.thumbnail}
              alt={`${data.title} — Sage Tech — Mobile Development Agency`}
            />

            <div className="xs-separator" />

            <div className="article__content__date">
              <Calendar size={18} color="#3cba4f" strokeWidth={1.5} />

              <div style={{ width: 6 }} />

              <p>{data.createdAt}</p>
            </div>
            <div className="xs-separator" />

            <div className="article__content__divider" />

            <div className="xs-separator" />

            <div className="article__content__text">{data.content}</div>
          </div>
        </div>
        <BookButtonContainer />
      </div>
    </Container>
  )
}

export default Article

function getArticle(id, navigate) {
  const article = articles.find(article => article.id === id)

  if (article) return article
  navigate('/not-found', { replace: true })
}
