import React from 'react'
import './benefits.scss'

function Benefits() {
  const benefitsListMap = (benefit, key) => {
    return (
      <div className="home__main__benefits__list__item" key={key}>
        <h5>
          <span>• </span> {benefit.title}
        </h5>

        <div className="xxs-separator" />

        <p>{benefit.description}</p>
      </div>
    )
  }

  return (
    <div className="home__main__benefits">
      <h3>Here is what you will get:</h3>

      <div className="xl-separator" />

      <div className="home__main__benefits__list">
        {benefits.map(benefitsListMap)}
      </div>
    </div>
  )
}

const benefits = [
  {
    title: 'On-time delivery ',
    description:
      'A commitment to meeting project deadlines, ensuring timely delivery',
  },
  {
    title: 'Scalable app',
    description:
      'Capable of handling increased user demand and accommodating future growth',
  },
  {
    title: 'Latest technologies',
    description:
      "Utilizing the newest and most effective technologies to enhance the app's functionality",
  },
  {
    title: 'Skilled developers',
    description:
      'A team of experienced and talented developers bringing expertise and creativity to your app idea',
  },
  {
    title: 'High-quality app',
    description:
      'A focus on writing clean, well-documented code that is easy to maintain, ensuring that the app continues to function optimally in the long term',
  },
  {
    title: 'Intuitive mobile design',
    description:
      'Ensuring the app has a user-friendly and visually appealing interface for optimal user experience',
  },
]

export default Benefits
