import React from 'react'
import './footer.scss'

function Footer() {
  return (
    <footer>
      <p>&copy; 2023 Sage Tech. All rights reserved.</p>
    </footer>
  )
}

export default Footer
