import {
  Check,
  Clipboard,
  Monitor,
} from 'feather-icons-react/build/IconComponents'
import React from 'react'
import './process.scss'

function Process() {
  const processListMap = (project, key) => {
    return (
      <div className="home__main__process__list__item" key={key}>
        {project.icon}

        <div className="xs-separator" />

        <h5>{project.title}</h5>

        <div className="xxs-separator" />

        <p>{project.description}</p>
      </div>
    )
  }

  return (
    <div className="home__main__process">
      <h3>What happens next:</h3>

      <div className="xl-separator" />

      <div className="home__main__process__list">
        {process.map(processListMap)}
      </div>
    </div>
  )
}

const process = [
  {
    icon: <Monitor color="white" />,
    title: 'Free Consultation',
    description: '',
  },
  {
    icon: <Clipboard color="white" />,
    title: '30-Minute Onboarding',
    description: '',
  },
  {
    icon: <Check color="white" />,
    title: 'Campaign Start',
    description: '',
  },
]

export default Process
