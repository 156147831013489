import { createBrowserRouter } from 'react-router-dom'
import { Blog, Home, Article, NotFound, Contact, CaseStudy } from '../../pages'

export default createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/contact',
    element: <Contact />,
  },
  {
    path: '/blog',
    element: <Blog />,
  },
  {
    path: '/blog/:id',
    element: <Article />,
  },
  {
    path: '/case-study/:id',
    element: <CaseStudy />,
  },
  { path: '/*', element: <NotFound /> },
])
