import React from 'react'
import './book_button.scss'

function BookButton() {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href="https://calendly.com/marius-atasiei/30min"
      className="bookButton animate__animated animate__fadeInUp animate__delay-1s">
      Book a Call
    </a>
  )
}

export default BookButton
