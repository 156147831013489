import FiveKeyPrinciplesForMobileAppUIUXDesign from './5-key-principles-for-mobile-app-ui-ux-design'
import TheBenefitsOfUsingFlutterForYourMobileApplicationDevelopment from './the-benefits-of-using-flutter-for-your-mobile-application-development'
import TopFrameworksForMobileAppDevelopmentAComprehensiveGuide from './top-frameworks-for-mobile-app-development-a-comprehensive-guide'
import UnderstandingMobileTechnologyTrendsAndHowToUtilizeThem from './understanding-mobile-technology-trends-and-how-to-utilize-them'

export const articles = [
  {
    id: 'top-frameworks-for-mobile-app-development-a-comprehensive-guide',
    title: 'Top Frameworks for Mobile App Development: A Comprehensive Guide',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/sagetech-26d1e.appspot.com/o/articles%2Fthumbnails%2Ftop-frameworks-for-mobile-app-development-a-comprehensive-guide.jpg?alt=media&token=7a7f8ad3-d428-40dc-bf03-0f9ef2a17fe6',
    preview: `Mobile app development frameworks are essential for creating high-quality and efficient mobile apps. With so many frameworks available, it can be challenging to choose the best one for your project. In this article, we will provide a comprehensive guide to some of the top frameworks for mobile app development.`,
    createdAt: '19 Feb 2022',
    content: <TopFrameworksForMobileAppDevelopmentAComprehensiveGuide />,
  },
  {
    id: '5-key-principles-for-mobile-app-ui-ux-design',
    title: '5 Key Principles for Mobile App UI/UX Design',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/sagetech-26d1e.appspot.com/o/articles%2Fthumbnails%2F5-key-principles-for-mobile-app-ui-ux-design.jpeg?alt=media&token=174ef818-c2c9-4a44-9218-201024397973',
    preview: `In today's digital world, user experience (UX) has become a crucial factor in determining the success of a mobile app. A well-designed mobile app that provides a great user experience can help retain and engage users, while a poorly designed app can drive users away. Here are five key principles to keep in mind when designing a mobile app's user interface (UI) and user experience (UX).`,
    createdAt: '14 Feb 2022',
    content: <FiveKeyPrinciplesForMobileAppUIUXDesign />,
  },
  {
    id: 'the-benefits-of-using-flutter-for-your-mobile-application-development',
    title:
      'The Benefits of Using Flutter for Your Mobile Application Development',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/sagetech-26d1e.appspot.com/o/articles%2Fthumbnails%2Fthe-benefits-of-using-flutter-for-your-mobile-application-development.jpeg?alt=media&token=6a956b1c-bc89-4b2d-840b-7b042046d082',
    preview: `In the fast-paced world of mobile app development, it is important to use a framework that provides maximum benefits with minimum drawbacks. Flutter, Google's open-source framework for creating beautiful, high-performance and high-fidelity mobile apps, ticks all the right boxes.`,
    createdAt: '10 Feb 2022',
    content: <TheBenefitsOfUsingFlutterForYourMobileApplicationDevelopment />,
  },
  {
    id: 'understanding-mobile-technology-trends-and-how-to-utilize-them',
    title: 'Understanding Mobile Technology Trends and How to Utilize Them',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/sagetech-26d1e.appspot.com/o/articles%2Fthumbnails%2Funderstanding-mobile-technology-trends-and-how-to-utilize-them.jpeg?alt=media&token=fdb8df72-96b7-4847-8017-773ba9cfb38a',
    preview: `As a mobile development agency, it's important to stay ahead of the latest technology trends in the industry. With the rapid pace of innovation, new tools, technologies, and best practices are emerging all the time. In this article, we'll discuss the current trends in mobile technology and how you can utilize them to enhance your app development process.`,
    createdAt: '07 Feb 2022',
    content: <UnderstandingMobileTechnologyTrendsAndHowToUtilizeThem />,
  },
]
