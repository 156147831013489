import React from 'react'
import { Helmet } from 'react-helmet'
import { Footer, Header } from './'
import './container.scss'

function Container({ children, title, content }) {
  return (
    <div className="container" id="container">
      <Helmet>
        <title>{title || 'Sage Tech - Web & Mobile Development Agency'}</title>
        <meta
          name="description"
          content={content || 'Sage Tech – Web & Mobile Development Agency.'}
        />
      </Helmet>

      <Header />

      <div className="content">{children}</div>

      <Footer />
    </div>
  )
}

export default Container
