import EducationalManagemenetPlatform from '../assets/portfolio/education-management-platform.jpg'
import LanguageLearningApp from '../assets/portfolio/translation-app.jpg'
import TransportationManagementApp from '../assets/portfolio/transportation-management-app.jpg'
import DataSecuritySolution from '../assets/portfolio/data-security-solution.jpg'
import CarAssistantApp from '../assets/portfolio/car-assistant-app.jpg'
import EventManagementPlatform from '../assets/portfolio/events-management-platform.jpg'
import MarketingAgencyWebsite from '../assets/portfolio/marketing-agency-website.jpg'
import MentalHealthPlatform from '../assets/portfolio/mental-health-platform.jpg'

export const caseStudies = [
  {
    id: 'educational-management-platform',
    title: 'Educational Management Platform',
    img: EducationalManagemenetPlatform,
    content: {
      overview:
        'Educational management platform that simplifies administrative tasks for schools and teachers, facilitates communication between teachers, students, and parents, and improves the overall educational experience.',
      objective:
        'The objective of this project was to develop a mobile app that can provide multiple features for every school role, enabling users to manage educational activities, communicate with other users, and access learning resources on-the-go. The app had to be user-friendly, fast, and efficient, while maintaining the security and privacy of user data.',
      approach:
        'To achieve the objectives of the project, we used the Flutter framework for development, which allowed us to create a cross-platform mobile app that was compatible with both iOS and Android devices. Our approach included the following steps:',
      steps: [
        "Requirement gathering: Start by understanding the client's requirements and expectations from the app. Discuss the app's features, functionality, and user interface design.",
        'Technology selection: Based on the requirements, identify the appropriate technology that can be used to develop the app.',
        'Project planning: Create a detailed project plan that outlines the development, deployment, and management tasks.',
        'Development: Develop the app using Flutter. Ensure that the app is compatible with different versions of Android and iOS devices.',
        'Deployment: Deploy the app on the app stores, ensuring that it meets the guidelines and requirements of the app stores.',
        'Project management: Efficiently manage the project tasks using an appropriate project management methodology. Ensure that the project tasks are completed within the defined timeline and budget.',
        'Maintenance and support: Provide ongoing maintenance and support to ensure that the app runs smoothly and remains up to date with the latest operating systems and frameworks.',
      ],
      result:
        "The app we developed provided multiple features for every school's role, making it easier for users to manage their educational activities on-the-go. The app's user-friendly design and intuitive interface made it easy for users to navigate and access their required features quickly. The app's performance was optimized, making it fast and efficient, and the security and privacy of user data were maintained.",
    },
    description:
      'Educational management platform that simplifies administrative tasks for schools and teachers, facilitates communication between teachers, students, and parents, and improves the overall educational experience.',
  },
  {
    id: 'transportation-management-app',
    title: 'Transportation Management App',
    img: TransportationManagementApp,
    description:
      'Transportation management platform designed for the logistics and field service industry, providing an all-in-one solution for resource scheduling, fleet, and incident management. It offers advanced reporting tools and ensures compliance with local labor laws.',
    content: {
      overview:
        'Our transportation management platform is designed for the logistics and field service industry, providing an all-in-one solution for resource scheduling, fleet, and incident management. It offers advanced reporting tools and ensures compliance with local labor laws.',
      objective:
        'To develop a cross-platform mobile app that provides an all-in-one solution for resource scheduling, fleet, and incident management, ensuring compliance with local labor laws.',
      approach:
        'To achieve the objectives of the project, we followed a well-defined approach, which included the following development steps:',
      steps: [
        "Requirement gathering: Start by understanding the client's requirements and expectations from the app. Discuss the app's features, functionality, and user interface design.",
        'Technology selection: Based on the requirements, identify the appropriate technology that can be used to develop the app.',
        'Project planning: Create a detailed project plan that outlines the development, deployment, and management tasks.',
        'Project management: Efficiently manage the project tasks using an appropriate project management methodology. Ensure that the project tasks are completed within the defined timeline and budget.',
        'Development: Develop the app using Flutter. Ensure that the app is compatible with different versions of Android and iOS devices.',
        'Deployment: Deploy the app on the app stores, ensuring that it meets the guidelines and requirements of the app stores.',
      ],
      result:
        'The app we developed provided a user-friendly, efficient, and compliant transportation management platform. Its reporting tool offered real-time analytics, and its intuitive interface made it easy for users to access required features quickly.',
    },
  },
  {
    id: 'language-learning-app',
    title: 'Language Learning App',
    img: LanguageLearningApp,
    description: `The language learning app is an all-in-one tool that provides high-quality translations, grammar checking, and language learning resources. It's designed to help users improve their language skills seamlessly.`,
    content: {
      overview:
        "The language learning app is an all-in-one tool that provides high-quality translations, grammar checking, and language learning resources. It's designed to help users improve their language skills seamlessly.",
      objective:
        'The objective of this project was to develop a native Android mobile app that could provide users with high-quality translations, grammar checking, and language learning resources. The app had to be user-friendly, fast, and efficient, while maintaining the accuracy and quality of translation and grammar checking.',
      approach:
        'To achieve the objectives of the project, we followed a well-defined approach, which included the following development steps:',
      steps: [
        "Requirement gathering: Start by understanding the client's requirements and expectations from the app. Discuss the app's features, functionality, and user interface design.",

        'Technology selection: Based on the requirements, identify the appropriate technology that can be used to develop the app.',

        'Project planning: Create a detailed project plan that outlines the development, deployment, and management tasks.',

        'Development: We developed the app using Java and Android Studio. We ensured that the app was compatible with different versions of Android devices.',

        'Deployment: We deployed the app on the Google Play Store, ensuring that it met the guidelines and requirements of the app store.',
      ],
      result:
        "The native Android mobile app we developed provided high-quality translations, grammar checking, and language learning resources, making it easier for users to learn and communicate in different languages. The app's user-friendly design and intuitive interface made it easy for users to navigate through the app and access their required features quickly.",
    },
  },
  {
    id: 'event-management-platform',
    title: 'Event Management Platform',
    img: EventManagementPlatform,
    description:
      'Event Management Platform is a self-service technology platform built for events of all sizes. It allows event organizers to create and manage their events with ease. The platform can be accessed via a website, and it offers a range of features that can be customized to meet the needs of different events.',
    content: {
      overview:
        'Event Management Platform is a self-service technology platform built for events of all sizes. It allows event organizers to create and manage their events with ease. The platform can be accessed via a website, and it offers a range of features that can be customized to meet the needs of different events.',
      objective:
        'The objective of this project was to develop a self-service technology platform that would provide event organizers with a user-friendly and efficient way to manage their events. The platform had to be customizable, fast, and efficient, while maintaining the security and privacy of event data.',
      approach:
        'To achieve the objectives of the project, we followed a well-defined approach, which included the following development steps:',
      steps: [
        "Requirement gathering: We started by understanding the client's requirements and expectations from the platform. We discussed the platform's features, functionality, and user interface design.",
        'Technology selection: Based on the requirements, we identified the appropriate technology that could be used to develop the platform.',
        'Project planning: We created a detailed project plan that outlined the development, deployment, and management tasks.',
        'Development: We developed the app using Java and Android Studio. We ensured that the app was compatible with different versions of Android devices.',
        'Deployment: We deployed the app on the Google Play Store, ensuring that it met the guidelines and requirements of the app store.',
      ],
      result:
        "The self-service technology platform we developed provided event organizers with a user-friendly and efficient way to manage their events. The platform's customizable features allowed organizers to tailor the platform to their specific event needs. The platform was fast and efficient, enabling organizers to manage their events in real-time. The platform's security and privacy measures ensured that event data was protected.",
    },
  },
  {
    id: 'data-security-solution',
    title: 'Data Security Solution',
    img: DataSecuritySolution,
    description:
      'The Data Security Solution app is a mobile app designed to provide secure communication and encryption to safeguard sensitive information.',
    content: {
      overview:
        'The Data Security Solution app is a mobile app designed to provide secure communication and encryption to safeguard sensitive information.',
      objective:
        "The main objective of the Data Security Solution app is to ensure maximum protection for users' valuable data. The app encrypts all communication, providing an added layer of security for sensitive information.",
      approach:
        'To achieve the objectives of the project, we followed a well-defined approach, which included the following development steps:',
      steps: [
        "Requirement gathering: Start by understanding the client's requirements and expectations from the app. Discuss the app's features, functionality, and user interface design.",
        'Technology selection: Based on the requirements, identify the appropriate technology that can be used to develop the app.',
        'Project planning: Create a detailed project plan that outlines the development, deployment, and management tasks.',
        'Development: We developed the app using Java and Android Studio. We ensured that the app was compatible with different versions of Android devices.',
        'Deployment: We deployed the app on the Google Play Store, ensuring that it met the guidelines and requirements of the app store.',
      ],
      result:
        'The Data Security Solution app is a promising solution for users who value data security. While it is a new app, it is expected to receive positive reviews from users who appreciate the ease of use and the added layer of security.',
    },
  },
  {
    id: 'mental-health-platform',
    title: 'Mental Health Platform',
    img: MentalHealthPlatform,
    description:
      'The Mental Health Platform is a web application designed to help people connect with certified mental health specialists, chat with them, and access mental health-related resources. The platform is built using React, TypeScript, Node.js, and MongoDB technologies.',
    content: {
      overview:
        'The Mental Health Platform is a web application designed to help people connect with certified mental health specialists, chat with them, and access mental health-related resources. The platform is built using React, TypeScript, Node.js, and MongoDB technologies.',
      objective:
        'The main objective of the Mental Health Platform is to provide a safe and secure space for people to seek mental health assistance. The platform should be easy to use, visually appealing, and provide relevant information about mental health. The platform should also enable mental health specialists to provide counseling services and share resources with their clients.',
      approach:
        'To achieve the objectives of the project, we followed a well-defined approach, which included the following development steps:',
      steps: [
        "Requirement gathering: We started by understanding the Mental Health Platform's requirements and expectations from the website. We discussed the platform's features, functionality, design, and the specific needs of mental health specialists and users.",
        'Technology selection: Based on the requirements, we identified the appropriate technology that could be used to develop the platform. We selected React, TypeScript, Node.js, and MongoDB for their scalability, flexibility, and ease of use.',
        'Project planning: We created a detailed project plan that outlined the development, deployment, and management tasks. We worked closely with the Mental Health Platform team to ensure that the project plan aligned with their goals and timeline.',
        'Development: We developed the platform using React, TypeScript, Node.js, and MongoDB. We implemented a responsive design that provided an optimal user experience across different devices and screen sizes. We also integrated MongoDB for data storage and Node.js for server-side functionality.',
        'Deployment: We deployed the platform on a web server, ensuring that it met the latest web standards, accessibility, and security guidelines.',
      ],
      result:
        "The Mental Health Platform is a user-friendly and secure platform that provides easy access to mental health services and resources. The platform's responsive design ensures that it is accessible across different devices and screen sizes. Mental health specialists can use the platform to connect with clients, provide counseling services, and share mental health resources. The platform's secure data storage and server-side functionality ensure that user data and mental health information are protected. The Mental Health Platform is a valuable resource for people seeking mental health assistance and mental health specialists who want to provide counseling services.",
    },
  },
  {
    id: 'marketing-agency-website',
    title: 'Marketing Agency Website',
    img: MarketingAgencyWebsite,
    description:
      'The Marketing Agency Website serves as a platform for their blog, admin dashboard and presentation of the agency. The website is built using React, TypeScript, and Firebase technologies.',
    content: {
      overview:
        'The Marketing Agency Website serves as a platform for their blog, admin dashboard and presentation of the agency. The website is built using React, TypeScript, and Firebase technologies.',
      objective:
        "The main objective of the Marketing Agency Website is to provide a seamless user experience for their clients and visitors. The website should be easy to navigate, visually appealing, and provide relevant information about their services. The blog platform should also be user-friendly, allowing the team to publish content and engage with their audience. The admin dashboard should provide a centralized location for managing the website's content and user data.",
      approach:
        'To achieve the objectives of the project, we followed a well-defined approach, which included the following development steps:',
      steps: [
        "Requirement gathering: We started by understanding the Marketing Agency Website's requirements and expectations from the website. We discussed the website's features, functionality, design, and the specific needs of the blog platform and admin dashboard.",
        'Technology selection: Based on the requirements, we identified the appropriate technology that could be used to develop the website. We selected React, TypeScript, and Firebase for their scalability, flexibility, and ease of use.',
        'Project planning: We created a detailed project plan that outlined the development, deployment, and management tasks. We worked closely with the Marketing Agency Website team to ensure that the project plan aligned with their goals and timeline.',
        'Development: We developed the website using React and TypeScript. We implemented a responsive design that provided an optimal user experience across different devices and screen sizes. We also integrated Firebase for the blog platform and admin dashboard.',
        'Deployment: We deployed the website on a web server, ensuring that it met the latest web standards, accessibility, and security guidelines.',
        'Project management: Efficiently manage the project tasks using an appropriate project management methodology. Ensure that the project tasks are completed within the defined timeline and budget.',
      ],
      result:
        "The Marketing Agency Website is a well-designed and user-friendly platform that provides a seamless experience for their clients and visitors. The website's responsive design ensures that it is accessible across different devices and screen sizes. The blog platform is easy to use, allowing the team to publish content and engage with their audience effectively. The admin dashboard provides a centralized location for managing the website's content and user data, streamlining the website's maintenance and management.",
    },
  },
  {
    id: 'car-assistant-app',
    title: 'Car Assistant App',
    img: CarAssistantApp,
    description:
      'Car Assistance App is a native Android mobile app that acts as your personal car assistant. It helps you avoid bad luck on the road by sending you notifications if something is wrong with your car. The app always shows you where your car is, so you never lose sight of it, even if someone else is driving it.',
    content: {
      overview:
        'Car Assistance App is a native Android mobile app that acts as your personal car assistant. It helps you avoid bad luck on the road by sending you notifications if something is wrong with your car. The app always shows you where your car is, so you never lose sight of it, even if someone else is driving it.',
      objective:
        'The objective of this project was to develop a native Android mobile app that would provide users with an easy-to-use and reliable car assistance app. The app had to be user-friendly, fast, and efficient, while maintaining the accuracy and quality of car diagnostics and location tracking.',
      approach:
        'To achieve the objectives of the project, we followed a well-defined approach, which included the following development steps:',
      steps: [
        "Requirement gathering: We started by understanding the client's requirements and expectations from the app. We discussed the app's features, functionality, and user interface design.",
        'Technology selection: Based on the requirements, we identified the appropriate technology that could be used to develop the app.',
        'Project planning: We created a detailed project plan that outlined the development, deployment, and management tasks.',
        'Development: We developed the app using Java and Android Studio. We ensured that the app was compatible with different versions of Android devices.',
        'Deployment: We deployed the app on the Google Play Store, ensuring that it met the guidelines and requirements of the app store.',
      ],
      result:
        "The native Android mobile app we developed provided users with an easy-to-use and reliable car assistance app that helped them avoid bad luck on the road. The app's user-friendly design and intuitive interface made it easy for users to navigate through the app and access their required features quickly. The app's car diagnostics and location tracking features were accurate and reliable, giving users peace of mind when driving their cars.",
    },
  },
]
