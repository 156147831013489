import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyALFFVrVp8OVGfugX_yYGy541ckLrF_zGI',
  authDomain: 'sagetech-26d1e.firebaseapp.com',
  projectId: 'sagetech-26d1e',
  storageBucket: 'sagetech-26d1e.appspot.com',
  messagingSenderId: '228142504622',
  appId: '1:228142504622:web:912c2054d0812544da6b3f',
  measurementId: 'G-69QQZV7NWM',
}

export const initializeFirebase = () => {
  const app = initializeApp(firebaseConfig)
  getAnalytics(app)
}
