import React, { useEffect, useState } from 'react'
import './snackbar.scss'

const Snackbar = ({ message, show, onHide, isError }) => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    if (show) {
      setActive(true)
      setTimeout(() => {
        setActive(false)
        onHide()
      }, 3000)
    }
  }, [show, onHide])

  return (
    <div
      className={`snackbar ${active ? 'show' : ''}`}
      style={{ backgroundColor: isError ? '#FF4D4D' : '#2D893B' }}>
      <p>{message}</p>
    </div>
  )
}

export default Snackbar
