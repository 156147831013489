import React, { useState } from 'react'
import { Container, Snackbar } from '../../core/components'
import Logo from '../../core/assets/logo.png'
import emailjs from 'emailjs-com'

import './contact.scss'

function Contact() {
  const [emailDetails, setEmailDetails] = useState(defaultEmailDetails)
  const [snackbarData, setSnackbarData] = useState(defaultSnackbarData)

  const handleInputChange = ev => {
    const { name, value } = ev.target
    setEmailDetails(prevEmailDetails => ({
      ...prevEmailDetails,
      [name]: value,
    }))
  }

  const handleSnackbarHide = () => {
    setSnackbarData(defaultSnackbarData)
  }

  const handleSubmit = async ev => {
    ev.preventDefault()

    try {
      if (!emailDetails.message || !emailDetails.email) {
        const unfilledInputs = []

        if (!emailDetails.email) unfilledInputs.push('email')
        if (!emailDetails.message) unfilledInputs.push('message')

        setSnackbarData({
          show: true,
          isError: true,
          message: 'Please enter your ' + unfilledInputs.join(' and '),
        })

        return
      }

      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        emailDetails,
        process.env.REACT_APP_EMAILJS_USER_ID
      )

      setSnackbarData({
        show: true,
        isError: false,
        message: 'Email sent successfully!',
      })

      setEmailDetails(defaultEmailDetails)
    } catch (e) {
      setSnackbarData({
        show: true,
        isError: true,
        message: 'Something went wrong. Please try again.',
      })

      console.log(e)
    }
  }

  return (
    <Container
      title="Contact | Mobile Development Agency"
      content="Contact for Mobile Development Agency">
      <div className="contact">
        <div className="contact__content">
          <div className="contact__content__details">
            <img src={Logo} alt="Sage Tech Logo" />

            <h1>Our team is ready to assist you.</h1>

            <div className="m-separator" />
            <p>
              If you want to contact us, fill in the form or send us an email at{' '}
              <strong>marius@sagetech.dev</strong>.
            </p>
          </div>

          <form className="contact__content__form" onSubmit={handleSubmit}>
            <div className="contact__content__form__top">
              <input
                placeholder="Name"
                name="name"
                value={emailDetails.name}
                onChange={handleInputChange}
              />
              <input
                placeholder="Email*"
                name="email"
                value={emailDetails.email}
                onChange={handleInputChange}
              />
            </div>

            <textarea
              placeholder="Message*"
              name="message"
              value={emailDetails.message}
              onChange={handleInputChange}
              draggable={false}
            />

            <button type="submit" onClick={handleSubmit}>
              Submit
            </button>
          </form>
        </div>

        <Snackbar
          message={snackbarData.message}
          isError={snackbarData.isError}
          show={snackbarData.show}
          onHide={handleSnackbarHide}
        />
      </div>
    </Container>
  )
}

const defaultEmailDetails = {
  name: '',
  email: '',
  message: '',
}

const defaultSnackbarData = {
  message: '',
  show: false,
  isErorr: false,
}

export default Contact
